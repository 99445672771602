/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import $ from 'jquery';
import 'jquery-match-height';
import 'responsive-bp/src/js/responsive.core.js';
import 'responsive-bp/src/js/responsive.dropdown.js';
import 'responsive-bp/src/js/responsive.tabs.js';
import pointerUrl from '../images/pointer.png';
import pointerGreyUrl from '../images/pointer-grey.png';
import toastr from 'toastr';
import confetti from 'canvas-confetti';
import Cookies from 'js-cookie';

$(async function () {
	var header = $('header');
	if (header.length) {
		// Navigation
		var navigation = header.find('.nav-main');
		if (navigation.length) {
			// Match nav container height
			navigation.find('> ul > li.sub').each(function () {
				$(this).find('ul.level-1, ul.level-2, ul.level-3, ul.level-4').matchHeight({
					property: 'height'
				});
			});

			// Add click to open/close
			var menuItems = navigation.find('li.sub').each(function () {
				var $this = $(this), link = $this.find('> a');
				link.on('click', function (e) {
					$this.siblings('li').removeClass('open');
					$this.siblings('li').find('li.sub').removeClass('open');

					if ($this.hasClass('open')) {
						$this.removeClass('open');
						$this.find('li.open').removeClass('open');

						$(document.body).removeClass('mask');
					}
					else {
						$this.addClass('open');

						$(document.body).addClass('mask');
					}

					e.preventDefault();
					e.stopPropagation();
				});
			});

			$(document.body).on('click', function () {
				menuItems.removeClass('open');
				$(document.body).removeClass('mask');
			});
		}

		// Responsive menu
		header.each(function () {
			var responsiveNav = header.find('.responsive-nav');

			var toggler = header.find('.toggler').click(function () {
				responsiveNav.toggleClass('-collapsed');
				toggler.toggleClass('is-active');
			});
		});
	}

	// Match agenda slider item height
	$('.agenda-slider-item').matchHeight({
		property: 'height'
	});

	// Match agenda slider item height
	$('.agenda-downloads__columns .agenda-downloads__download').matchHeight({
		property: 'height'
	});

	// Search toggler
	$('.search').each(function () {
		var $this = $(this),
			toggler = $this.find('.search__toggler');

		toggler.click(function () {
			$('#search-input').focus();
			$this.toggleClass('search--closed');
			toggler.toggleClass('search__toggler--closed');
		}).appendTo($this);
	});

	// Filter
	$('#project-form, #agenda-form, #downloads-form').each(async function () {
		await import('jquery-ajax-unobtrusive');

		var $form = $(this);
		$form.find('input, select').on('change', function () {
			$form.submit();
		});
		$form.find('.filter__clear').on('click', function () {
			$form.find('select').val('');
			$form.find('input[type=checkbox]').prop('checked', false);
			$form.submit();
		});
		$form.find('.filter__item.-button').css('display', 'none');
	});

	// Lightbox
	$('body:has([rel=lightbox])').each(async function () {
		await import(/* webpackChunkName: 'lightgallery' */ 'lightgallery');
		await import(/* webpackChunkName: 'lightgallery' */ 'lg-video');

		$(this).lightGallery({
			selector: '[rel=lightbox]',
			download: false,
			thumbnail: false,
			fullScreen: false,
			actualSize: false,
			hash: false
		});
	});

	// Slideshow
	$('.agenda-slider .swiper-container').each(async function () {
		const Swiper = (await import('swiper/dist/js/swiper.js')).default;

		new Swiper(this, {
			grabCursor: true,
			loop: true,
			effect: 'fade',
			autoplay: {
				delay: 3000
			},
			speed: 1500,
			pagination: {
				el: '.swiper-pagination',
				clickable: true
			}
		});
	});

	//// Google Maps 
	//$('.google-maps:not(.map)').each(async function () {
	//	var $this = $(this);

	//	// Load Google Maps
	//	const loadGoogleMapsApi = (await import('load-google-maps-api')).default;
	//	const googleMaps = await loadGoogleMapsApi({
	//		key: $this.data('api-key')
	//	});
		
	//	var map = new googleMaps.Map($this[0], {
	//		zoom: 17,
	//		zoomControl: false,
	//		mapTypeControl: false,
	//		scaleControl: false,
	//		streetViewControl: false,
	//		rotateControl: false,
	//		fullscreenControl: false
	//	});

	//	var bounds = new google.maps.LatLngBounds();

	//	$.each($this.data('geolocation').split(';'), function () {
	//		var latLng = this.split(',');

	//		var marker = new google.maps.Marker({
	//			position: {
	//				lat: parseFloat(latLng[0]),
	//				lng: parseFloat(latLng[1])
	//			},
	//			map: map,
	//			icon: {
	//				url: pointerUrl,
	//				scaledSize: new google.maps.Size(32, 54),
	//				origin: new google.maps.Point(0, 0),
	//				anchor: new google.maps.Point(16, 54)
	//			}
	//		});

	//		bounds.extend(marker.position);
	//	});

	//	map.fitBounds(bounds);
	//});

	// Distributors
	$('.distributors__table').each(async function () {
		var $this = $(this),
			container = $this.parent(),
			mapDiv = $this.next();

		if (container.length && mapDiv.length) {
			// Load Google Maps
			const loadGoogleMapsApi = (await import('load-google-maps-api')).default;
			const googleMaps = await loadGoogleMapsApi({
				key: mapDiv.data('google-key')
			});

			var map = new googleMaps.Map(mapDiv[0], {
				zoom: 17,
				maxZoom: 13,
				zoomControl: true,
				mapTypeControl: true,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false
			});

			var bounds = new google.maps.LatLngBounds();

			// Get data
			await import('table-to-json');
			var countries = container.data('countries');
			var deliveryAreasText = container.data('deliveryareas-text');
			var currentInfoWindow = null;
			var data = $.map($this.tableToJSON(), function (data) {
				// Parse JSON array
				data.deliveryAreaKeys = JSON.parse(data.deliveryAreaKeys);

				var latLng = data.location.split(',');

				var marker = new google.maps.Marker({
					position: {
						lat: parseFloat(latLng[0]),
						lng: parseFloat(latLng[1])
					},
					map: map,
					icon: {
						url: pointerUrl,
						scaledSize: new google.maps.Size(32, 54),
						origin: new google.maps.Point(0, 0),
						anchor: new google.maps.Point(16, 54)
					}
				});

				bounds.extend(marker.position);

				var infoWindowContent = $('<div class="info-window">');

				if (data.name) {
					infoWindowContent.append($('<h3>', {
						text: data.name
					}));
				}

				var infoWindowContentAddress = $('<p>').appendTo(infoWindowContent);
				if (data.street) {
					infoWindowContentAddress.append(document.createTextNode(data.street));
				}

				if (data.zipCode) {
					infoWindowContentAddress.append('<br />', document.createTextNode(data.zipCode));
				}

				if (data.city) {
					infoWindowContentAddress.append('<br />', document.createTextNode(data.city));
				}

				if (data.countryKey) {
					infoWindowContentAddress.append('<br />', document.createTextNode(countries[data.countryKey]));
				}

				var infoWindowContentContact = $('<p>').appendTo(infoWindowContent);
				if (data.telephone) {
					infoWindowContentContact.append(document.createTextNode(data.telephone));
				}

				if (data.email) {
					infoWindowContentContact.append('<br />', $('<a>', {
						href: 'mailto:' + data.email,
						text: data.email
					}));
				}

				if (data.website) {
					infoWindowContentContact.append('<br />', $('<a>', {
						href: data.website,
						target: '_blank',
						rel: 'noopener',
						text: data.website
					}));
				}

				if (data.deliveryAreaKeys.length) {
					var infoWindowContentDeliveryAreas = $('<p>').appendTo(infoWindowContent);
					infoWindowContentDeliveryAreas.append(
						$('<strong>', {
							text: deliveryAreasText + ':'
						}),
						$.map(data.deliveryAreaKeys, function (key) {
							return ['<br />', document.createTextNode(countries[key])];
						})
					);
				}

				var infoWindow = new google.maps.InfoWindow({
					content: infoWindowContent[0]
				});

				var openInfoWindow = function () {
					if (currentInfoWindow) {
						currentInfoWindow.close();
					}
					currentInfoWindow = infoWindow;

					infoWindow.open(map, marker);
				};

				marker.addListener('click', openInfoWindow);

				var element = $('<li class="distributors__list-item">').click(function () {
					openInfoWindow();
				}).append($('<strong>', {
					text: data.name
				}));

				if (data.city) {
					element.append(document.createTextNode(' / ' + data.city));
				}

				if (data.countryKey) {
					element.append(document.createTextNode(', ' + countries[data.countryKey]));
				}

				return $.extend(data, {
					marker: marker,
					infoWindow: infoWindow,
					element: element
				});
			});

			map.fitBounds(bounds);

			// Add country select
			var countriesText = container.data('countries-text');
			$('<div class="distributors__country-picker">').append(
				$('<select>').change(function () {
					// Close open info window
					if (currentInfoWindow) {
						currentInfoWindow.close();
						currentInfoWindow = null;
					}

					var value = this.value,
						fitBoundsData = [],
						filteredData = data.filter(function (data) {
							// Reset, detach from list and remove marker
							data.element.removeClass('delivery-area').detach();
							data.marker.icon.url = pointerUrl;
							data.marker.setMap(null);

							var show = !value || value === data.countryKey;
							if (show) {
								// Also add to fit bounds
								fitBoundsData.push(data);
							} else if (data.deliveryAreaKeys.indexOf(value) > -1) {
								// Add as delivery area
								show = true;
								data.element.addClass('delivery-area');
								data.marker.icon.url = pointerGreyUrl;
							}

							return show;
						});

					// Add to list
					list.append($.map(filteredData, function (data) {
						return data.element;
					}));

					// Add to map and fit within bounds
					bounds = new google.maps.LatLngBounds();
					$.each(filteredData, function () {
						this.marker.setMap(map);

						if (fitBoundsData.length === 0 || fitBoundsData.indexOf(this) !== -1) {
							bounds.extend(this.marker.position);
						}
					});
					map.fitBounds(bounds, { top: 120 });
				}).append($('<option>', {
					value: '',
					text: countriesText
				})).append($.map(countries, function (text, value) {
					return $('<option>', {
						value: value,
						text: text
					});
				}))
			).prependTo(container);

			// Add to list
			var headerText = container.data('header-text');
			var list = $('<ul class="distributors__list">').append($.map(data, function (data) {
				return data.element;
			}));
			$('<div class="distributors__list-container same-height">').append(
				$('<h2 class="distributors__title title">').text(headerText),
				list
			).appendTo(container);
		}

		// Remove table from DOM
		$this.remove();
	});

	// Locations
	$('.locations__table').each(async function () {
		var $this = $(this),
			container = $this.parent(),
			mapDiv = $this.next();

		if (container.length && mapDiv.length) {
			// Load Google Maps
			const loadGoogleMapsApi = (await import('load-google-maps-api')).default;
			const googleMaps = await loadGoogleMapsApi({
				key: mapDiv.data('api-key')
			});

			var map = new googleMaps.Map(mapDiv[0], {
				zoom: 17,
				maxZoom: 13,
				zoomControl: true,
				mapTypeControl: true,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false
			});

			var bounds = new google.maps.LatLngBounds();

			// Get data
			await import('table-to-json');
			var currentInfoWindow = null;
			var data = $.map($this.tableToJSON(), function (data) {
				var latLng = data.location.split(',');

				var marker = new google.maps.Marker({
					position: {
						lat: parseFloat(latLng[0]),
						lng: parseFloat(latLng[1])
					},
					map: map,
					icon: {
						url: pointerUrl,
						scaledSize: new google.maps.Size(32, 54),
						origin: new google.maps.Point(0, 0),
						anchor: new google.maps.Point(16, 54)
					}
				});

				bounds.extend(marker.position);

				var infoWindowContent = $('<div class="info-window">');

				if (data.name) {
					infoWindowContent.append($('<h3>', {
						text: data.name
					}));
				}

				var infoWindowContentAddress = $('<p>').appendTo(infoWindowContent);
				if (data.street) {
					infoWindowContentAddress.append(document.createTextNode(data.street));
				}

				if (data.zipCodeCity) {
					infoWindowContentAddress.append('<br />', document.createTextNode(data.zipCodeCity));
				}

				var infoWindowContentContact = $('<p>').appendTo(infoWindowContent);
				if (data.telephone) {
					infoWindowContentContact.append(document.createTextNode(data.telephone));
				}

				if (data.email) {
					infoWindowContentContact.append('<br />', $('<a>', {
						href: 'mailto:' + data.email,
						text: data.email
					}));
				}

				var infoWindow = new google.maps.InfoWindow({
					content: infoWindowContent[0]
				});

				var openInfoWindow = function () {
					if (currentInfoWindow) {
						currentInfoWindow.close();
					}
					currentInfoWindow = infoWindow;

					infoWindow.open(map, marker);
				};

				marker.addListener('click', openInfoWindow);

				var element = $('<li class="distributors__list-item">').click(function () {
					openInfoWindow();
				}).append($('<strong>', {
					text: data.name
				}));

				if (data.city) {
					element.append(document.createTextNode(' / ' + data.city));
				}

				if (data.countryKey) {
					element.append(document.createTextNode(', ' + countries[data.countryKey]));
				}

				return $.extend(data, {
					marker: marker,
					infoWindow: infoWindow,
					element: element
				});
			});

			map.fitBounds(bounds);
		}

		// Remove table from DOM
		$this.remove();
	});

	// File uploads
	$('.fileupload input').each(function () {
		var $this = $(this);
		var label = $this.next();

		$this.on('change', function (e) {
			var files = $this.prop('files');

			console.log(files);

			var fileName = null;
			if (files.length > 1) {
				fileName = (this.data('multiple-caption') || '').replace('{0}', files.length);
			} else {
				fileName = $this.val().split('\\').pop();
			}

			if (fileName) {
				label.text(fileName);
			}
		});
	});

	// Jump to submitted form
	$('.form__wrapper:has(form)').each(function () {
		var $this = $(this),
			$form = $this.find('form'),
			anchor = $this.find('.anchor').attr('name');

		$form.attr('action', $form.attr('action') + '#' + anchor);
	});

	//Toastr ctrl
	var body = document.querySelector('body');
	if (body) {
		var pageId = body.dataset.pageId;
		if (pageId > 0) {
			var request = new XMLHttpRequest();
			request.open('GET', '/umbraco/surface/Toastr/GetToastr?pageId=' + pageId + '&culture=' + document.documentElement.lang, true);
			request.onload = function () {
				if (request.status >= 200 && request.status < 400) {
					if (request.responseText !== "" && Object.keys(request.responseText).length > 0) {
						var response = JSON.parse(request.responseText);
						if (response.id != undefined) {
							var cookieName = 'toast',
								ignoredIds = Cookies.get(cookieName),
								ignoreId = function (id) {
									if (ignoredIds) ignoredIds += ',' + id;
									else ignoredIds = id;
									Cookies.set(cookieName, ignoredIds);
								};

							if (!ignoredIds || !ignoredIds.includes(response.id)) {
								// Set options
								toastr.options.progressBar = true;
								toastr.options.closeButton = true;
								toastr.options.timeOut = 10000;
								toastr.options.extendedTimeOut = 5000;
								toastr.options.showDuration = 0,
									toastr.options.target = 'header',
									toastr.options.iconClasses = { info: 'notification' };

								toastr.info('', '<div class="content-top"><i class="anniversary-logo"></i><h2 class="title">' + response.title + '</h2><a href="' + response.redButtonUrl + '" class="button"><span>' + response.redButtonName + '</span></a></div><div class="buttons-below"><a class="button-play" href="' + response.linkLeftUrl + '"><i></i><span>' + response.linkLeftName + '</span></a><a class="button-play" href="' + response.linkRightUrl + '"><i></i><span>' + response.linkRightName + '</span></a></div>', {
									onHidden: function () {
										ignoreId(response.id);
									}
								}).on({
									click: function () {
										ignoreId(response.id);
									}
								});

								var colors = ["#fdc500", "#fedc66", "#f5f5f5"];
								var totalConfettiFrames = 0;
								var totalConfettiFramesMax = 120;
								var myCanvas = document.createElement('canvas');

								//body.appendChild(myCanvas);

								function frame() {

									var myConfetti = confetti.create(myCanvas, {
										resize: true,
										useWorker: true,
									});

									confetti({
										particleCount: 3,
										angle: 60,
										spread: 55,
										origin: { x: 0 },
										colors: colors,
										zIndex: 10000
									});

									confetti({
										particleCount: 3,
										angle: 120,
										spread: 55,
										origin: { x: 1 },
										colors: colors,
										zIndex: 10000
									});

									totalConfettiFrames++;

									if (totalConfettiFrames < totalConfettiFramesMax) {
										requestAnimationFrame(frame);
									}
								}

								frame();
							}
						}
					}
				}
			};

			try {
				request.send();
			} catch (error) {
				// Handle the error here and prevent it from being logged
				console.log("No events found.");
			}
		}
	}
});